import React from 'react';
import { InnerPageLayout } from 'components/InnerPageLayout';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import headshot from '../images/headshot.jpg';
import { theme } from '../../config/theme';
import css from '@emotion/css';
import { fonts } from '../../src/lib/typography';
import GatsbyImage from 'gatsby-image';

const BigH1 = styled.h1`
  font-size: 3em;
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;
`;

const HeadshotContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30em',
  [`@media (min-width: 1024px)`]: {
    flexShrink: 0,
    position: 'relative',
    marginRight: '5em',
    ':before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '105%',
      top: '-4%',
      left: '-5%',
      transform: 'scale(1.1)',
      transformOrigin: 'top left',
      // backgroundColor: '#bbb',
      // backgroundColor: theme.colors.bodyOnSecondary,
      // backgroundColor: '#737f86',
      backgroundColor: '#fff',
      boxShadow: '1px 1px 7px rgba(0,0,0,.4)',
    },
  },
});

const HeadshotLayout = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 30em;
  height: 67vh;
`;

const Headshot = styled(GatsbyImage)`
  flex-grow: 1;
  object-fit: cover;
  margin-bottom: 0;
`;

const FlexiGrow = ({ children }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const CenteringElement = styled.div`
    margin: auto 0;
  `;
  return (
    <Container>
      <CenteringElement>{children}</CenteringElement>
    </Container>
  );
};

const BoldLink = styled.a`
  font-weight: 600;
`;

export default ({ data: { file, site, allMdx } }) => {
  return (
    <InnerPageLayout site={site} twoColumnLayout>
      <HeadshotLayout>
        <Headshot fluid={file.childImageSharp.fluid} />
      </HeadshotLayout>
      <div
        css={css`
          @media (min-width: 600px) {
            margin: auto;
          }
        `}
      >
        <p
          css={css`
            @media (min-width: 600px) {
              margin-top: -5em;
            }
          `}
        >
          Alisha Jeddeloh is a writer; the fiction editor of{' '}
          <i>
            <BoldLink
              href="https://www.brinkliterary.com/"
              target="_blank"
            >
              Brink
            </BoldLink>,
          </i>{' '}
          an in-print literary journal dedicated to publishing hybrid, cross-genre work of 
          both emerging and established creatives; 
          and an editor of the{' '}
          <i>
            <BoldLink
              href="https://www.biculturalwritersfellowship.com/books-1"
              target="_blank"
            >
              We the Interwoven
            </BoldLink>
          </i>{' '}
          series of bicultural anthologies. In her writing, she explores the lies we tell ourselves and others, 
          as well as the tension between individual and community, insiders and outsiders. 
          She lives in Iowa City, Iowa, with her family, where she is currently working on a novel
          that examines the intersection of astrology, technology, and transcendence. She can be found at
          alishajeddeloh at gmail or{' '}
          <a target="_blank" href="https://www.instagram.com/alishajeddeloh/">
            @alishajeddeloh
          </a>{' '}
          on Instagram.
        </p>
      </div>
    </InnerPageLayout>
  );
};

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "headshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`;
